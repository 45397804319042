import { Authentication_Status } from "./models/Authentication_Status";
import { Authentication_Page } from "./models/Authentication_Page";
import { Authentication_Google } from "./Authentication_Google";
import { Authentication_Microsoft } from "./Authentication_Microsoft";
import { Authentication_Basic } from "./Authentication_Basic";
import { RecaptchaInfo } from "./models/RecaptchaInfo";

declare global {
    interface Window {
        initGapi: () => void;
        initRecaptcha: () => void;
        recaptchaCallbackSignIn: () => void;
        recaptchaCallbackSignUp: () => void;
        recaptchaCallbackReset: () => void;
    }
}

declare const app: AppLegacy;
interface AppLegacy {
    sendToken: (string) => void;
}

declare const recaptcha: RecaptchaInfo;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class Authentication {
    private google: Authentication_Google;

    private msft: Authentication_Microsoft;

    private basic: Authentication_Basic;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public static readonly instance: Authentication = new Authentication();

    private constructor() {
        $(() => {
            this.init();
        });

        window["showLoginScreen"] = this.showLoginScreen;
    }

    private init = () => {
        this.google = new Authentication_Google(this);
        this.msft = new Authentication_Microsoft(this);
        this.basic = new Authentication_Basic(this);

        this.initListeners();
        this.init2Factor();

        this.showLoginScreen();
    };

    private initListeners = () => {
        $("#loginBackground").on("click", () => {
            this.hideLoginScreen();
        });
        $("#login-alt-resetpass").on("click", () => {
            this.changeLoginScreen(Authentication_Page.RESET);
        });
        $("#login-alt-signup").on("click", () => {
            this.changeLoginScreen(Authentication_Page.SIGN_UP);
        });
        $(".login-alt-resetlogin").on("click", () => {
            this.changeLoginScreen(Authentication_Page.SIGN_IN);
        });
    };

    private init2Factor = () => {
        $("#login-2auth-android").on("click", () => {
            $("#login-2auth-apple").hide();
            $("#login-2auth-windows").hide();
            $(".login-2auth-pickdevice-sep").hide();

            $("#login-2auth-pickapp").show();

            $("#login-2auth-msft").hide();
            $("#login-2auth-googleauth").attr("href", "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2");
            $("#login-2auth-authy").attr("href", "https://play.google.com/store/apps/details?id=com.authy.authy");
        });
        $("#login-2auth-apple").on("click", () => {
            $("#login-2auth-android").hide();
            $("#login-2auth-windows").hide();
            $(".login-2auth-pickdevice-sep").hide();

            $("#login-2auth-pickapp").show();

            $("#login-2auth-msft").hide();
            $("#login-2auth-googleauth").attr("href", "https://itunes.apple.com/ca/app/google-authenticator/id388497605");
            $("#login-2auth-authy").attr("href", "https://itunes.apple.com/ca/app/authy/id494168017");
        });
        $("#login-2auth-windows").on("click", () => {
            $("#login-2auth-android").hide();
            $("#login-2auth-apple").hide();
            $(".login-2auth-pickdevice-sep").hide();

            $("#login-2auth-pickapp").show();

            $("#login-2auth-androidapple").hide();
            $("#login-2auth-microsoft").attr("href", "https://www.microsoft.com/en-ca/store/apps/authenticator/9wzdncrfj3rj");
        });
    };

    private showLoginScreen = () => {
        $("#loginContainer").fadeIn("fast");
    };

    private hideLoginScreen = () => {
        const container = $("#loginContainer");
        if (!container.hasClass("perma")) {
            container.fadeOut("fast");
        }
    };

    private changeLoginScreen = (page: Authentication_Page) => {
        const loginFrame = $("#loginFrame"),
            loginTitle = $("#login-title");

        switch (page) {
            case Authentication_Page.SIGN_IN:
                loginFrame.attr("data-page", "login");
                if ($("#login-page3").is(":visible")) {
                    $("#login-page3").fadeOut("fast", () => {
                        $("#login-page1").fadeIn("fast");
                    });
                } else {
                    $("#login-page2").fadeOut("fast", () => {
                        $("#login-page1").fadeIn("fast");
                    });
                }
                loginTitle.fadeOut("fast", () => {
                    loginTitle.text("Login").fadeIn("fast");
                });
                break;
            case Authentication_Page.SIGN_UP:
                loginFrame.attr("data-page", "signup");
                if ($("#login-page3").is(":visible")) {
                    $("#login-page3").fadeOut("fast", () => {
                        $("#login-page2").fadeIn("fast");
                    });
                } else {
                    $("#login-page1").fadeOut("fast", () => {
                        $("#login-page2").fadeIn("fast");
                    });
                }
                loginTitle.fadeOut("fast", () => {
                    loginTitle.text("Sign Up").fadeIn("fast");
                });
                break;
            case Authentication_Page.RESET:
                loginFrame.attr("data-page", "reset");
                $("#login-page1").fadeOut("fast", () => {
                    $("#login-page3").fadeIn("fast");
                });
                $("#login-page2").fadeOut("fast");
                loginTitle.fadeOut("fast", () => {
                    loginTitle.text("Reset Password").fadeIn("fast");
                });
                break;
            default:
                break;
        }
    };

    public toggleLoginFrame = (val: boolean) => {
        const loginContent = $("#loginFrameContent"),
            loginLoading = $("#loginFrameLoading");
        loginContent.stop();
        loginLoading.stop();
        if (val) {
            const height = loginContent.height() + parseInt($(loginContent.children()[0]).css("marginTop"));
            loginContent.fadeOut("slow", () => {
                loginLoading.height(height).fadeIn("fast");
            });
            $("#loginLoading").fadeIn("slow");
        } else {
            $("#loginLoading").fadeOut("slow");
            loginLoading.fadeOut("fast", () => {
                loginContent.fadeIn("slow");
            });
        }
    };

    public redirectUser = (token) => {
        if (!$("#loginContainer").hasClass("perma")) {
            location.reload();
        } else {
            switch (getUrlVars()["redirect"]) {
                case "app":
                    app.sendToken(token);
                    break;
                case "mobile":
                    window.location.href = "/mobile";
                    break;
                default:
                    window.location.href = "/";
                    break;
            }
        }
    };

    public updateStatus = (type: Authentication_Status, page: Authentication_Page, message: string) => {
        let prefix = "",
            midfix;

        switch (type) {
            case Authentication_Status.ERROR:
                prefix = "Error: ";
                break;
            case Authentication_Status.SUCCESS:
                prefix = "Success: ";
                break;
            default:
                break;
        }
        switch (page) {
            case Authentication_Page.SIGN_UP:
                midfix = "signup";
                break;
            case Authentication_Page.RESET:
                midfix = "reset";
                break;
            case Authentication_Page.RESET_END:
                midfix = "resetEnd";
                break;
            case Authentication_Page.SIGN_IN:
            default:
                midfix = "signin";
                break;
        }

        this.toggleLoginFrame(false);
        $(`#login-${midfix}-error`).fadeOut(0).fadeIn("fast")
            .text(prefix + message);
    };
}

function initRecaptcha() {
    if (!recaptcha.enabled) {
        return;
    }

    if (document.getElementById("login-signin").getAttribute("data-recaptcha-enabled") === "true") {
        recaptcha[0] = grecaptcha.render("login-signin-placeholder-submit", {
            "badge": "inline",
            "sitekey": recaptcha.publicKey,
            // @ts-ignore
            "callback": "recaptchaCallbackSignIn"
        });
    }

    if (document.getElementById("login-signup").getAttribute("data-recaptcha-enabled") === "true") {
        recaptcha[1] = grecaptcha.render("login-signup-placeholder-submit", {
            "badge": "inline",
            "sitekey": recaptcha.publicKey,
            // @ts-ignore
            "callback": "recaptchaCallbackSignUp"
        });
    }

    if (document.getElementById("login-reset").getAttribute("data-recaptcha-enabled") === "true") {
        recaptcha[2] = grecaptcha.render("login-reset-placeholder-submit", {
            "badge": "inline",
            "sitekey": recaptcha.publicKey,
            // @ts-ignore
            "callback": "recaptchaCallbackReset"
        });
    }
}

function recaptchaCallbackSignIn() {
    $("#login-signin-form").trigger("submit");
}
function recaptchaCallbackSignUp() {
    $("#login-signup-form").trigger("submit");
}
function recaptchaCallbackReset() {
    $("#login-reset-form").trigger("submit");
}

window.initRecaptcha = initRecaptcha;
window.recaptchaCallbackSignIn = recaptchaCallbackSignIn;
window.recaptchaCallbackSignUp = recaptchaCallbackSignUp;
window.recaptchaCallbackReset = recaptchaCallbackReset;
