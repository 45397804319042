import { Authentication_Status } from "./models/Authentication_Status";
import { Authentication_Page } from "./models/Authentication_Page";
import { Authentication } from "./Authentication";
import * as msal from "@azure/msal-browser";

export class Authentication_Microsoft {
    public static instance: Authentication_Microsoft = null;

    private parent: Authentication;

    private msalInstance: msal.PublicClientApplication;

    private static readonly CONFIG: msal.Configuration = {
        auth: {
            clientId: "73c6103a-2125-4f05-ab27-5e6260328212",
        },
    };

    private static readonly LOGIN_REQUEST: { scopes: string[] } = {
        scopes: ["user.read", "email", "profile"],
    };

    public constructor(parent: Authentication) {
        if (Authentication_Microsoft.instance != null) {
            throw new Error("Invalid operation, only one instance of Authentication_Microsoft is allowed");
        }

        Authentication_Microsoft.instance = this;
        this.parent = parent;
        this.msalInstance = new msal.PublicClientApplication(Authentication_Microsoft.CONFIG);

        this.init();
    }

    private init = () => {
        $("#login-msftSigninButton")
            .on("click", () => {
                this.parent.toggleLoginFrame(true);

                let recievedMessage = false;
                const rnd = Math.random().toString(36).slice(2),
                    loginWindow = window.open(`https://login.live.com/oauth20_authorize.srf?client_id=73c6103a-2125-4f05-ab27-5e6260328212&scope=wl.emails,wl.basic&response_type=code&redirect_uri=${window.location.origin}${window.location.pathname}auth/msft?verify=${rnd}`, "", "width=565,height=725"),
                    startMessageCheck = () => {
                        setTimeout(() => {
                            if (!recievedMessage && loginWindow.closed) {
                                this.parent.updateStatus(Authentication_Status.ERROR, Authentication_Page.SIGN_IN, "Login window was closed");
                                recievedMessage = true;
                            }
                            if (!recievedMessage) {
                                loginWindow.postMessage(rnd, "*");
                                startMessageCheck();
                            }
                        }, 500);
                    };
                startMessageCheck();

                const receiveMessage = (event) => {
                    if (typeof event.data === "object" && event.data.verify === rnd) {
                        recievedMessage = true;
                        console.log(event.data);
                        if (event.data.status === "logged_in") {
                            loginWindow.close();
                            window.location.reload();
                        } else if (event.data.status === "2factor") {
                            loginWindow.close();
                            window.location.href = `${window.location.origin}/auth/2auth?signIn`;
                        } else if (event.data.status === "error") {
                            this.parent.updateStatus(Authentication_Status.ERROR, Authentication_Page.SIGN_IN, event.data.error);
                            console.log(event);
                            loginWindow.close();
                        }
                    }
                };
                window.addEventListener("message", receiveMessage);
            });
    };

}
