import { Authentication_Status } from "./models/Authentication_Status";
import { Authentication_Page } from "./models/Authentication_Page";
import { Authentication } from "./Authentication";
import { RecaptchaInfo } from "./models/RecaptchaInfo";

declare const recaptcha: RecaptchaInfo;

export class Authentication_Basic {
    public static instance: Authentication_Basic = null;

    private parent: Authentication;

    public constructor(parent: Authentication) {
        if (Authentication_Basic.instance != null) {
            throw new Error("Invalid operation, only one instance of Authentication_Basic is allowed");
        }

        Authentication_Basic.instance = this;
        this.parent = parent;

        this.init();
    }

    private init = () => {
        const signinForm = $("#login-signin-form"),
            signupForm = $("#login-signup-form"),
            resetForm = $("#login-reset-form");

        signinForm.on("submit", (e) => {
            e.preventDefault();

            const formData = signinForm.serialize();
            this.parent.toggleLoginFrame(true);
            $.ajax({
                type: "POST",
                url: signinForm.attr("action"),
                data: formData,
                success: (data) => {
                    if (data.status === "success") {
                        this.parent.redirectUser(data.token);
                    } else if (data.status === "2factor") {
                        window.location.href = `${window.location.origin}/auth/2auth?signIn`;
                    } else if (data.status === "error") {
                        this.parent.updateStatus(Authentication_Status.ERROR, Authentication_Page.SIGN_IN, data.error);
                        if (typeof (grecaptcha) !== "undefined" && recaptcha.enabled) {
                            grecaptcha.reset(recaptcha[0]);
                        }
                        if (data.useCaptcha !== null && data.useCaptcha && !recaptcha[0]) {
                            recaptcha[0] = grecaptcha.render("login-signin-placeholder-submit", {
                                "badge": "inline",
                                "sitekey": recaptcha.publicKey,
                                // @ts-ignore
                                "callback": "recaptchaCallback_signin",
                            });
                        }
                    }
                },
            });
        });

        signupForm.on("submit", (e) => {
            e.preventDefault();

            const formData = signupForm.serialize();
            $.ajax({
                type: "POST",
                url: signupForm.attr("action"),
                data: formData,
                success: (data) => {
                    if (data.status === "success") {
                        this.parent.redirectUser(data.token);
                    } else if (data.status === "error") {
                        this.parent.updateStatus(Authentication_Status.ERROR, Authentication_Page.SIGN_UP, data.error);
                        if (typeof (grecaptcha) !== "undefined" && recaptcha.enabled) {
                            grecaptcha.reset(recaptcha[1]);
                        }
                    }
                },
            });
        });

        resetForm.on("submit", (e) => {
            e.preventDefault();
            this.parent.toggleLoginFrame(true);

            const formData = resetForm.serialize();
            $.ajax({
                type: "POST",
                url: resetForm.attr("action"),
                data: formData,
                success: (data) => {
                    if (data.status === "success") {
                        this.parent.updateStatus(Authentication_Status.SUCCESS, Authentication_Page.RESET, data.success);
                        $(".login-reset-input")
                            .hide();
                        $("#login-reset-submit-cont")
                            .hide();
                    } else if (data.status === "error") {
                        this.parent.updateStatus(Authentication_Status.ERROR, Authentication_Page.RESET, data.error);
                        if (typeof (grecaptcha) !== "undefined" && recaptcha.enabled) {
                            grecaptcha.reset(recaptcha[2]);
                        }
                    }
                },
                complete: () => {
                    this.parent.toggleLoginFrame(false);
                },
            });
        });

        $("#login-resetEnd-form")
            .on("submit", (e) => {
                e.preventDefault();
                const formData = $(this)
                    .serialize();

                $.ajax({
                    type: "POST",
                    url: $(this)
                        .attr("action"),
                    data: formData,
                    success: (data) => {
                        if (data.status === "success") {
                            this.parent.updateStatus(Authentication_Status.SUCCESS, Authentication_Page.RESET_END, "Password successfully reset");

                            $("#login-resetEnd-form")
                                .html("");
                            $("#login-resetEnd-home")
                                .show();
                        } else if (data.status === "error") {
                            this.parent.updateStatus(Authentication_Status.ERROR, Authentication_Page.RESET_END, data.error);
                            if (typeof (grecaptcha) !== "undefined" && recaptcha.enabled) {
                                grecaptcha.reset(recaptcha[0]);
                            }
                        }
                    }
                });
            });
    };

}
