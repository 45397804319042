/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-call */
import { Authentication } from "./Authentication";

declare const google: any;

export class Authentication_Google {
    public static instance: Authentication_Google = null;

    private parent: Authentication;

    private googleAuth: gapi.auth2.GoogleAuth = null;

    public constructor(parent: Authentication) {
        if (Authentication_Google.instance != null) {
            throw new Error("Invalid operation, only one instance of Authentication_Google is allowed");
        }

        Authentication_Google.instance = this;
        this.parent = parent;

        gapi.load("auth2", () => {
            this.googleAuth = gapi.auth2.init({});
            this.googleAuth.attachClickHandler($("#login-googSigninButton")[0], {}, (data) => {
                this.parent.toggleLoginFrame(true);
                this.gsiCallback({ credential: data.getAuthResponse().id_token });
            }, (err) => {
                this.parent.toggleLoginFrame(false);
            });
        });
        window.onGoogleLibraryLoad = () => {
            google.accounts.id.initialize({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                client_id: document.querySelector<HTMLMetaElement>("meta[name='google-signin-clientid']").content,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                cancel_on_tap_outside: false,
                callback: this.gsiCallback,
                context: "signin",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                auto_select: "true"
            });
            google.accounts.id.prompt();
        };
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private gsiCallback = (data: { credential: string }) => {
        $.ajax({
            type: "POST",
            url: "/auth/gplus?authIdToken",
            success: (authData) => {
                if (authData.status === "logged_in") {
                    this.parent.redirectUser(authData.token);
                } else if (authData.status === "2factor") {
                    window.location.href = `${window.location.origin}/auth/2auth?signIn`;
                } else {
                    this.parent.toggleLoginFrame(false);
                }
            },
            data: { idToken: data.credential }
        });
    };
}
